<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />
    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="font-18 me-auto">{{ jobTitle }}</h1>
    </div>
    <div class="page-title-clear"></div>
    <!-- End Page Title-->

    <div class="page-content mt-4">
      <EmptyState
        v-if="listJob.length === 0"
        :message1="message1"
        :message2="'yang di ajukan'"
      />
      <Job v-for="job in listJob" :key="job.id" :job="job" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";
import Job from "@/components/Job";
export default {
  name: "Apply",
  components: {
    Header,
    Footer,
    Job,
    EmptyState,
  },
  data() {
    return {
      jobTitle: "",
      listJob: [],
      apiUrl: "/api/v1/job-seeker/job/apply/list/",
      isLoading: true,
      message1: "",
      message2: "",
    };
  },
  mounted() {
    init_template();
    var job_type = this.$route.params.job_type;
    if (job_type === "project") {
      this.jobTitle = "Pengajuan Freelance";
      this.message1 = "Belum ada freelance";

      this.getJobList("PJ");
    }
    if (job_type === "vacancy") {
      this.jobTitle = "Pengajuan Lowongan";
      this.message1 = "Belum ada lowongan";
      this.getJobList("VC");
    }
  },
  methods: {
    getJobList(job_type) {
      var url = this.apiUrl + "?job_type=" + job_type;
      axios
        .get(url)
        .then((response) => {
          this.listJob = response.data.map((item) => {
            return item.job;
          });
          console.log("this.listJob", this.listJob);
          this.isLoading = false;
        })
        .catch((error) => {});
    },
  },
};
</script>

<template>
  <div class="card card-style mt-1">
    <div class="content mt-3 mb-2">
      <!-- Job Passed -->
      <div v-if="job.job_passed" class="card-top">
        <a class="btn btn-s bg-red-dark color-white mt-0 float-end">{{
          passedTitle
        }}</a>
      </div>
      <!-- End Job Passed -->

      <h4 class="mb-2">{{ job.title }}</h4>
      <span class="badge bg-orange-dark mb-3 p-1 me-1">{{
        job.get_category
      }}</span>
      <span class="badge bg-yellow-dark mb-3 p-1 me-1">{{
        job.work_loc_city.name
      }}</span>
      <span class="badge bg-blue-light mb-3 p-1 me-1">{{
        job.get_worktime_display
      }}</span>
      <h5 class="font-13 opacity-70 line-height-s">
        {{ job.get_level }}
      </h5>
      <h5 class="font-13 line-height-s mb-2">
        <span v-if="!(job.salary_start === job.salary_end)">
          Rp {{ $filters.formatPrice(job.salary_start) }} - Rp{{
            $filters.formatPrice(job.salary_end)
          }}
        </span>
        <span v-if="job.salary_start === job.salary_end && !job.hide_salary">
          Sekitar Rp {{ $filters.formatPrice(job.salary_start) }}
        </span>
      </h5>
      <!-- Read More-->
      <div class="d-flex">
        <div class="me-auto">
          <div class="list-group list-custom-large mt-n3">
            <a href="#" class="border-0">
              <img :src="logoUrl" class="rounded-sm" />
              <span>{{ job.company.business_name }}</span>
              <strong>{{ job.company.city.name }}</strong>
            </a>
          </div>
        </div>
        <div>
          <router-link
            :to="{ name: 'JobDetail', params: { id: job.id } }"
            href="#"
            class="
              btn btn-full btn-s
              font-600
              rounded-s
              gradient-highlight
              mt-1
              float-start
            "
            >Lihat</router-link
          >
        </div>
      </div>

      <div v-if="job.job_passed" class="card-overlay bg-black opacity-60"></div>
      <!-- End Read More-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Job",
  props: {
    job: Object,
  },
  data() {
    return {
      logoUrl: this.job.company.get_logo,
      passedTitle: "",
    };
  },
  mounted() {
    this.job.job_type == "VC"
      ? (this.passedTitle = "Lowongan Berakhir")
      : (this.passedTitle = "Freelance Berakhir");
  },
};
</script>
